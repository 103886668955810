<template>
    <div class="activity-schedule">
        <div class="return_page">
            <a @click="goBack">
                <i class="fas fa-arrow-left"></i>
                <span class="mobile">
                    {{ $t("vigik.navigation.list-activity") }}
                </span>
            </a>
        </div>
        <div class="top-block" ref="titleUpdateScheduleActivity">
            <h1 class="title">
                {{
                    $t("vigik.schedule.title", {
                        name: $route.params.activityName,
                    })
                }}
            </h1>
        </div>
        <week-calendar
            v-if="display"
            :readonly="false"
            :days="days"
            :maxTimeRange="10"
            :maxDifferentDay="7"
            :initialValue="initialValue"
        />
        <div class="center" v-else>
            <Loader />
        </div>
    </div>
</template>

<script>
import WeekCalendar from "@/components/basic/WeekCalendarLite"
import BasicButton from "@/components/basic/BasicButton.vue"
import {
    getActivitySchedule,
    saveActivitySchedule,
} from "@/services/intratone/accesstype"
import Loader from "@/components/basic/Loader"
export default {
    name: "ActivitySchedule",
    components: {
        Loader,
        WeekCalendar,
    },
    data() {
        return {
            days: [
                { id: 0, name: "monday" },
                { id: 1, name: "tuesday" },
                { id: 2, name: "wednesday" },
                { id: 3, name: "thursday" },
                { id: 4, name: "friday" },
                { id: 5, name: "saturday" },
                { id: 6, name: "sunday" },
            ],
            initialValue: [],
            display: false,
            displayArray: [],
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.display = false
            getActivitySchedule(
                this.$route.params.accessId,
                this.$route.params.activityId,
                { origin: 1 }
            ).then((response) => {
                this.initialValue = response.data.days
                this.display = true
            })
        },
        goBack() {
            window.history.back()
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";

@media all and (max-width: 768px) {
    .mobile {
        display: none;
    }
}

.activity-schedule {
    width: 100vw;
    height: 100%;
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
    overflow: auto;
    .center {
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        align-items: center;
    }
    .return_page {
        @media all and (max-width: 768px) {
            margin: 20px;
        }
        @media all and (min-width: 768px) {
            margin: 0 auto;
        }
        height: 4%;
        a {
            color: $blue-darker;
            cursor: pointer;
            text-decoration: none;
            transition: color 200ms;
            font-family: "Avenir Heavy";
            font-size: 12px;
            i {
                margin-right: 10px;
                transition: margin 200ms;
            }
        }
        a:hover {
            color: $orange-neutral;
            i {
                margin-right: 15px;
            }
        }
    }
    .top-block {
        padding-bottom: 6em;
        .title {
            font-family: $font_avenir_black;
            width: 100%;
            @media all and (min-width: 768px) {
                font-size: $veryBig;
            }
            @media all and (max-width: 768px) {
                font-size: $big;
            }
        }
        @media all and (max-width: 768px) {
            padding-bottom: 20px;
            margin-top: -60px;
            padding-left: 40px;
            padding-bottom: 6em;
        }
    }
}
</style>
